<template>
  <b-modal
    id="form-sidebar"
    ref="sideBar"
    size="lg"
    :visible="isActive"
    backdrop
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    bg-variant="white"
    title="Páginas"
    right
    shadow
    ok-title="Salvar"
    cancel-title="Cancelar"
    sidebar-class="sidebar-lg"
    header-class="content-sidebar-header"
    footer-class="content-sidebar-footer"
    @ok="onSubmit"
    @cancel="onCancel"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #header="{hide}">
      <div class="d-flex justify-content-between align-items-center w-100">
        <feather-icon
          class="ml-1 cursor-pointer float-right"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
    </template>
    <template #footer="{hide}">
      <div class="p-1">
        <b-row>
          <b-col
            sm="12"
            md="12"
            class="text-right"
          >

            <!-- Form Actions -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              class="mr-2"
              @click="onSubmit"
            >

              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              type="button"
              @click="onCancel"
            >

              <font-awesome-icon
                prefix="far"
                :icon="['far', 'times-circle']"
                class="mr-50"
              />
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <template #default="{ hide }">
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.name"
                rules="required"
                label="Nome"
                placeholder="informe o nome da página"
                name="name"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-vue-select
                v-model="model.type"
                rules="required"
                label="Tipo"
                placeholder="selecione o tipo da página"
                name="type"
                :options="pageTypes"
                @input="setHelperDescription()"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.url"
                rules="required"
                label="Destino"
                placeholder="informe o endereço da página"
                :helper-description="helperDescription"
                name="url"
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pageTypes from '@/variables/pageTypes'

export default {
  components: {
    I2FormInput,
    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    uuid: {
      type: String,
      required: false,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      pageTypes,
      helperDescription: '',
      model: {
        uuid: null,
        name: '',
        type: '',
        url: '',
      },
    }
  },
  watch: {
    uuid(value) {
      this.model.uuid = value
      this.getDataPage()
    },
  },
  methods: {
    async getDataPage() {
      if (!this.model.uuid) {
        return
      }
      const response = await this.$http.get(`pages/${this.model.uuid}`)
      this.model = {
        uuid: response.uuid,
        name: response.name,
        type: pageTypes.find(type => type.value === response.type),
        url: response.url,
      }
    },
    onSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.refFormObserver.validate()
        .then(async success => {
          if (success) {
            let response = {}
            const data = { ...this.model }
            data.type = this.model.type.value
            if (this.model.uuid) {
              response = await this.$http.put(`pages/${this.model.uuid}`, data)
            } else {
              response = await this.$http.post('pages', data)
            }
            if (response.error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.error_message,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
              return
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Página salvo com sucesso.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.resetModel()
            this.$emit('onPageSave', response)
            this.$refs.refFormObserver.reset()
          }
        })
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.resetModel()
      this.$refs.refFormObserver.reset()
    },
    resetModel() {
      this.model = {
        uuid: null,
        name: null,
        menus: [],
      }
    },
    setHelperDescription() {
      if (!this.model.type.value) {
        this.helperDescription = ''
        return
      }
      if (this.model.type.value === 'report') {
        this.helperDescription = 'Exemplo: 0ccfcff5-4669-4c23-98f1-3e87e4749142'
      }

      if (this.model.type.value === 'url') {
        this.helperDescription = 'Exemplo: https://www.neteye.com.br/'
      }

      if (this.model.type.value === 'route') {
        this.helperDescription = 'Exemplo: pages.list'
      }
    },
  },
}
</script>
