const pageTypes = [
  {
    value: 'url',
    text: 'URL',
  },
  {
    value: 'report',
    text: 'Relatório',
  },
  {
    value: 'route',
    text: 'Rota',
  },
]
export default pageTypes
