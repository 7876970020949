<template>
  <b-container>
    <page-header name="Páginas">
      <b-button
        variant="primary"
        @click="newPage"
      >
        <FeatherIcon icon="PlusIcon" />
        &nbsp;<span class="text-nowrap">Adicionar</span>
      </b-button>
    </page-header>

    <page-new
      :uuid="uuidPage"
      :is-active.sync="isActivated"
      @onPageSave="onPageSave()"
      @onCancel="onCancelEdit()"
    />

    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
    >

      <template
        #cell(type)="data"
      >
        {{ getTypeName(data.item) }}
      </template>

      <template
        #cell(action)="data"
        style="width: 20%"
      >
        <div class="px-4 text-center">
          <i2-icon-button
            icon="EditIcon"
            variant="outline-primary"
            class="mr-1"
            @click="setCurrentPage(data.item)"
          />
          <i2-icon-button
            fa-icon="fa-trash"
            variant="outline-danger"
            class="mr-1"
            @click="deleteProfile(data.item)"
          />
        </div>
      </template>
    </Grid>
  </b-container>
</template>

<script>
import Grid from '@/components/i2/grid/Grid.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PageNew from '@/views/pages/PageNew.vue'
import pageTypes from '../../variables/pageTypes'

export default {
  components: {
    Grid,
    PageNew,
  },
  data() {
    return {
      uuidPage: null,
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-20',
        },
        {
          key: 'type',
          label: 'Tipo',
          class: 'w-10',
        },
        {
          key: 'url',
          label: 'Destino',
          class: 'w-40',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-20',
        },
      ],
      items: [],
      isActivated: false,
    }
  },
  watch: {
    isActivated(val) {
      if (!val) {
        this.uuidPage = null
      }
    },
  },
  mounted() {
    this.getPages()
  },
  methods: {
    pageTypes() {
      return pageTypes
    },
    async getPages() {
      const response = await this.$http.get('pages')
      this.items = response
    },
    setCurrentPage(page) {
      this.uuidPage = page.uuid
      this.isActivated = true
    },
    newPage() {
      this.uuidPage = null
      this.isActivated = true
    },
    onPageSave() {
      this.uuidPage = null
      this.isActivated = false
      this.getPages()
    },
    deleteProfile(page) {
      this.$bvModal.msgBoxConfirm('Por favor, confirme a exclusão da página.', {
        title: 'Confirm exclusão?',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async value => {
          if (value) {
            const response = await this.$http.delete(`pages/${page.uuid}`)
            if (response.error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.error_message,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `A página ${page.name} foi removida com sucesso.`,
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.getPages()
            }
          }
          return true
        })
    },
    onCancelEdit() {
      this.isActivated = false
      this.uuidPage = null
    },
    getTypeName(page) {
      let typeName = ''
      pageTypes.map(pageType => {
        if (page.type === pageType.value) {
          typeName = pageType.text
        }
      })
      return typeName
    },
  },
}
</script>
<style>

</style>
